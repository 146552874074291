import { lazy } from 'react';

const MainMenuComponent = lazy(() =>
    import('../../presentation/main/view/mainmenu/MainMenuComponent')
);

const ProductDetailComponent = lazy(() =>
    import('../../presentation/main/view/product-detail/ProductDetailComponent')
);

const MainRoutes = [
    {
        key: "key.root",
        path: '/',
        component: MainMenuComponent,
        name: ''
    },
    {
        key: "key.otherroot",
        path: '/voucherGame',
        component: MainMenuComponent,
        name: ''
    },
    {
        key: "key.detailproduk",
        path: '/detailproduk',
        component: ProductDetailComponent,
        name: ''
    }
];

export default MainRoutes;