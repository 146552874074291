import React from 'react';
import "./assets/css/_global.scss";
import { Routers } from './presentation/routers';
import { SnackbarProvider } from '@projectaltair/snackbarprovider'
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false
    }
  }
});

function App() {
  return (
    
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <Routers />
        </QueryClientProvider>
      </SnackbarProvider>
    
  );
}

export default App;
