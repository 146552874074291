const CryptoJS = require('crypto-js');

export const decodeBase64 = (param: string) => {
    const paramBase64 = CryptoJS.enc.Base64.parse(param);
    const paramHeaders = CryptoJS.enc.Utf8.stringify(paramBase64);
    return paramHeaders;
}

export const encodeBase64 = (param: string) => {
    const paramHeaders = CryptoJS.enc.Utf8.parse(param);
    const paramBase64 = CryptoJS.enc.Base64.stringify(paramHeaders);
    return paramBase64;
}