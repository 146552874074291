import React from 'react'
import './index.scss'


function PageNotFoundComponent() {
    console.log("Page Not Found")

    return (
        <>
        </>
    )
}

export default (PageNotFoundComponent);
