import Swal from "sweetalert2";
import { LoadingBackdrop } from '@projectaltair/loadingbackdrop';
import React from "react";


export const onShowAlerts = (alerts: string, isExpired?: boolean) => {

    const alert = alerts.toLowerCase();
    const { REACT_APP_URL_REDIRECT_GAME } = process.env;

    let redirectUrl: string;

    if(isExpired){
        redirectUrl = REACT_APP_URL_REDIRECT_GAME || '';
    }else{
        redirectUrl = `/`;
    }

    Swal.fire({
        icon: 'info',
        title: 'Info',
        confirmButtonColor: "#7cd1f9",
        text: alert != "invalid jwt" ? alerts! : "Your session is ended. Please back to home apps.",
        allowOutsideClick: false,
    }).then((result) => {
        if (result.isConfirmed) {
            return window.location.href = redirectUrl;
        }
    })

}

export const onShowLoading = (isLoading: boolean) => (
    <LoadingBackdrop
        open={isLoading}
        onClick={() => console.log('clicked')}
    />
)

export const onShowPopup = (title: string, information: string, color: string, imgPath?: any) => {
    Swal.fire({
        confirmButtonColor: color,
        titleText: title,
        html: information,
        imageUrl: imgPath,
        allowOutsideClick: false,
        confirmButtonText: 'OK, Mengerti'
    }).then(function () {
        Swal.close();
    })
}