import React from "react";

import { onShowAlerts } from "../../../../config/utils/alerts";
import { debounce } from "@mui/material";
import { decodeBase64 } from "../../../../config/utils/main";
import Cookies from "universal-cookie";

export function useMainViewModel() {

    const cookies = new Cookies();

    const [searchQuery, setSearchQuery] = React.useState('');
    const token = cookies.get('auth-token');
    const params = cookies.get('params');
    
    const handleSearch = debounce((query: string) => {
        if(query.length >= 3 || query.length === 0){
            setSearchQuery(query);
        }
    }, 1000);

    React.useEffect(() => {
        const paramHeaders = !!params ? JSON.parse(decodeBase64(params)) : null;
        if (!token || !paramHeaders) {
            onShowAlerts(token ? "Invalid Parameter" : "Your session is ended. Please back to home apps.", !token);
        }
        
    }, [token, params]);

    return {
        searchQuery,
        handleSearch
    }
}